<template>
  <div>
    <app-page-detail page-title="Edit Resume Contract" page-toolbar-title="Edit Resume Contract" :record-not-found="notFound">
      <template #toolbar>
        <app-button @click="getDetail(true)" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>
        <!-- <app-button @click="printDocument()" title="Print" mdi-icon="mdi-printer"></app-button> -->
        <app-log-data-dialog module_name="resumecontract" :id="resume_contract_header.id">
          <app-button title="Document Log" mdi-icon="mdi-clipboard-text-clock"></app-button>
        </app-log-data-dialog>
        <v-divider vertical class="mx-2"></v-divider>
        <app-button @click="redirect('Finance.ResumeContract.Detail', { id: id })" title="Detail" mdi-icon="mdi-file-find"></app-button>
        <app-button :loading="loading_save" v-if="resume_contract_header.status == 'open'" @click="saveData()" mdi-icon="mdi-content-save" title="Save"></app-button>
      </template>
      <v-row v-if="resume_contract_header.status == 'open'">
        <v-col cols="12" lg="9">
          <v-row>
            <v-col cols="12" md="12">
              <card-expansion title="General" class="mb-3" :loading="loading">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field dense disabled readonly v-model="resume_contract_header.document_no_" placeholder="No." label="No." outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense disabled readonly v-model="resume_contract_header.document_date" type="date" placeholder="Doc. Date" label="Doc. Date" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field dense disabled readonly v-model="resume_contract_header.area_code" placeholder="Area Code" label="Area Code" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense disabled readonly v-model="resume_contract_header.status" placeholder="Status" label="Status" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>

                <v-divider class="mb-2"></v-divider>

                <v-row>
                  <v-col cols="6" md="6">
                    <v-text-field dense v-model="resume_contract_header.contract_number" placeholder="Nomor Kontrak" label="Nomor Kontrak" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="resume_contract_header.contract_date" placeholder="Tanggal" label="Tanggal" type="date" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="resume_contract_header.first_party" placeholder="Pihak Ke Satu" label="Pihak Ke Satu" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="resume_contract_header.second_party" placeholder="Pihak Ke Dua" label="Pihak Ke Dua" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="resume_contract_header.scope_of_work" placeholder="Lingkup Pekerjaan" label="Lingkup Pekerjaan" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field dense v-model="resume_contract_header.customer_name" placeholder="Project Owner" label="Project Owner" outlined hide-details class="mb-2"></v-text-field>
                    <v-select dense :items="$app_options.finance.resume_contract.customer_status" v-model="resume_contract_header.customer_status" label="Customer Status" outlined hide-details class="mb-2"></v-select>
                    
                    <v-text-field dense v-model="resume_contract_header.campaign_no_" placeholder="Campaign No." label="Campaign No." outlined hide-details class="mb-2" append-icon="mdi-dots-horizontal" @click:append="tsearch2.dialog = true" readonly></v-text-field>
                    <v-text-field readonly dense v-model="resume_contract_header.campaign_description" placeholder="Campaign Description" label="Campaign Description" outlined hide-details class="mb-2"></v-text-field>

                    <!-- <v-text-field dense v-model="resume_contract_header.campaign_no_" placeholder="Campaign No." label="Campaign No." outlined hide-details class="mb-2"></v-text-field> -->
                    <!-- <v-autocomplete dense v-model="selected_campaign" :search-input.sync="search_campaign" placeholder="Proyek" label="Proyek" return-object :items="campaign_list" item-text="no_" item-value="no_" outlined hide-details class="mb-2" :loading="loading_search_campaign">
                      <template v-slot:item="{ item }">
                        <v-list-item-avatar color="indigo" class="text-h5 font-weight-light white--text">
                          {{ item.description.charAt(0) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.no_ }}</v-list-item-title>
                          <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete> -->
                    <!-- <v-text-field dense v-model="resume_contract_header.campaign_description" placeholder="Campaign Description" label="Campaign Description" outlined hide-details class="mb-2"></v-text-field> -->
                    <app-text-field
                      dense
                      :value="grandTotal"
                      @input="
                        () => {
                          resume_contract_header.contact_value = grandTotal;
                        }
                      "
                      placeholder="Nilai Kontrak"
                      label="Nilai Kontrak"
                      outlined
                      hide-details
                      class="mb-2"
                      readonly
                      disabled
                      reverse
                      type="number"
                    ></app-text-field>
                  </v-col>
                </v-row>

                <v-divider class="my-6"></v-divider>

                <v-row>
                  <v-col cols="12" md="12">
                    <v-textarea dense v-model="resume_contract_header.execution_time" rows="2" placeholder="Waktu Pelaksanaan" label="Waktu Pelaksanaan" hide-details type="date" outlined class="mb-2"></v-textarea>
                    <v-textarea dense v-model="resume_contract_header.delivery_conditions" rows="2" placeholder="Pengiriman" label="Pengiriman" hint="LOCO/FRANCO, PARTIAL/TIDAK, TARGET FULFILL, KONDISI PENGIRIMAN" persistent-hint outlined class="mb-2"></v-textarea>
                    <v-textarea dense v-model="resume_contract_header.payment_terms" rows="2" placeholder="Cara Pembayaran" label="Cara Pembayaran" hint="DP, JAMINAN, COMMERCIAL MODEL, TERMS OF PAYMENT, SKEMA PEMBAYARAN" persistent-hint outlined class="mb-2"></v-textarea>
                    <v-textarea dense v-model="resume_contract_header.warranty_period" rows="2" placeholder="Masa Garansi/Pemeliharaan" label="Masa Garansi/Pemeliharaan" hide-details outlined class="mb-2"></v-textarea>
                    <v-textarea dense v-model="resume_contract_header.penalties_fines" rows="2" placeholder="Sanksi dan Denda" label="Sanksi dan Denda" hide-details outlined class="mb-2"></v-textarea>
                    <v-textarea dense v-model="resume_contract_header.damage_loss_claims" rows="2" placeholder="Klaim Rusak & Hilang" label="Klaim Rusak & Hilang" hide-details outlined class="mb-2"></v-textarea>
                    <v-textarea dense v-model="resume_contract_header.other_terms" rows="2" placeholder="Others" label="Others" hide-details outlined class="mb-2"></v-textarea>
                  </v-col>
                </v-row>
              </card-expansion>
              <card-expansion title="Detail" class="mb-6" :loading="loading" no-padding>
                <template slot="action">
                  <v-btn @click="showDialogAddDetail()" color="primary" icon>
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <v-row>
                  <v-col>
                    <v-data-table :items="resume_contract_detail" :headers="datatable_detail.headers" :loading="datatable_detail.loading" class="elevation-0">
                      <template v-slot:[`item.action`]="{ item }">
                        <v-btn @click="deleteDetail(item)" class="mr-2" icon>
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn @click="showDialogEditDetail(item)" class="mr-2" icon>
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.quantity`]="{ item }">
                        <span>{{ numberFormat(item.quantity) }}</span>
                      </template>
                      <template v-slot:[`item.unit_price`]="{ item }">
                        <span>{{ numberFormat(item.unit_price) }}</span>
                      </template>
                      <template v-slot:[`item.amount`]="{ item }">
                        <span>{{ numberFormat(item.quantity * item.unit_price) }}</span>
                      </template>
                    </v-data-table>
                    <v-divider class="mb-2"></v-divider>
                    <v-row>
                      <v-col>
                        <div class="d-flex justify-end px-4">
                          <div style="width: 300px">
                            <app-text-field class="mb-2" label="Jumlah" :value="totalAmount" type="number" :decimal-places="2" outlined hide-details dense reverse disabled></app-text-field>
                          </div>
                        </div>
                        <div class="d-flex justify-end px-4">
                          <div style="width: 100px">
                            <app-text-field class="mb-2 mx-1" label="VAT %" v-model="resume_contract_header.vat" type="number" :decimal-places="2" outlined hide-details dense reverse></app-text-field>
                          </div>
                          <div style="width: 300px">
                            <app-text-field class="mb-2" label="VAT Amount" :value="vatValue" type="number" :decimal-places="2" outlined hide-details dense reverse disabled></app-text-field>
                            <app-text-field class="mb-2" label="DPP" :value="dppAmount" type="number" :decimal-places="2" outlined hide-details dense reverse disabled></app-text-field>
                            <app-text-field class="mb-2" label="Nilai Total" :value="grandTotal" type="number" :decimal-places="2" outlined hide-details dense reverse disabled></app-text-field>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </card-expansion>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="3">
          <document-info :resume_contract_header="resume_contract_header"></document-info>
          <app-comment :document_id="parseInt(resume_contract_header.id)" :source_document="resume_contract_header.source_document" :document_no_="resume_contract_header.document_no_"></app-comment>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-alert type="warning" icon="mdi-information" dense>
            This document cannot be edited.<br><a href="javascript:void();" @click="redirect('Finance.ResumeContract.Detail', { id: id })">View Detail</a>
          </v-alert>
        </v-col>
      </v-row>
    </app-page-detail>
    <app-dialog v-model="dialogAddDetail" title="Add Detail" width="600px">
      <v-card>
        <v-card-text>
          <input v-model="dialogAddDetail.id" />
          <v-textarea label="Deskripsi Barang" placeholder="Deskripsi Barang" outlined hide-details class="mb-2" dense v-model="dialogAddDetailData.description"></v-textarea>
          <v-text-field label="Satuan" placeholder="Satuan" outlined hide-details class="mb-2" dense v-model="dialogAddDetailData.uom"></v-text-field>
          <app-text-field label="Unit" placeholder="Unit" outlined hide-details class="mb-2" dense v-model="dialogAddDetailData.quantity" type="number" reverse></app-text-field>
          <app-text-field label="Harga Satuan (Rp)" placeholder="Harga Satuan (Rp)" outlined hide-details class="mb-2" dense v-model="dialogAddDetailData.unit_price" type="number" reverse></app-text-field>
        </v-card-text>
      </v-card>

      <template slot="actions">
        <v-btn @click="addDetail()" color="primary">Add</v-btn>
      </template>
    </app-dialog>
    <app-dialog v-model="dialogEditDetail" title="Edit Detail" width="600px">
      <v-card>
        <v-card-text>
          <input v-model="dialogEditDetail.id" />
          <v-textarea label="Deskripsi Barang" placeholder="Deskripsi Barang" outlined hide-details class="mb-2" dense v-model="dialogEditDetailData.description"></v-textarea>
          <v-text-field label="Satuan" placeholder="Satuan" outlined hide-details class="mb-2" dense v-model="dialogEditDetailData.uom"></v-text-field>
          <app-text-field label="Unit" placeholder="Unit" outlined hide-details class="mb-2" dense v-model="dialogEditDetailData.quantity" type="number" reverse></app-text-field>
          <app-text-field label="Harga Satuan (Rp)" placeholder="Harga Satuan (Rp)" outlined hide-details class="mb-2" dense v-model="dialogEditDetailData.unit_price" type="number" reverse></app-text-field>
        </v-card-text>
      </v-card>

      <template slot="actions">
        <v-btn @click="updateDetail()" color="primary">Update</v-btn>
      </template>
    </app-dialog>
    <app-table-search-dialog v-model="tsearch2.dialog" title="Search Campaign" :uri="tsearch2.uri" :headers="tsearch2.headers" @row-selected="handleRowSelectedCampaign"></app-table-search-dialog>
  </div>
</template>

<script>
// import ApprovalList from "./components/ApprovalList.vue";
import DocumentInfo from "./components/DocumentInfo.vue";
// import ActionButton from './components/ActionButton.vue';
// import SetCloseDocument from "./components/SetCloseDocument.vue";

import AppTextField from "@/components/AppTextField.vue";

export default {
  components: {
    // CommentList,
    // ApprovalList,
    DocumentInfo,
    // ActionButton,
    // SetCloseDocument,
    AppTextField,
  },
  data() {
    return {
      id: null,
      loading: false,
      notFound: false,
      data_detail: {},
      resume_contract_header: {},
      resume_contract_detail: [],
      resume_contract_approval: [],
      loading_save: false,
      campaign_list: [],
      search_campaign: "",
      loading_search_campaign: false,
      selected_campaign: {
        no_ : '',
        description : '',
      },

      datatable_detail: {
        loading: false,
        headers: [
          { text: "#", value: "action" },
          { text: "Deskripsi Barang", value: "description" },
          { text: "Satuan", value: "uom" },
          { text: "Unit", value: "quantity", cellClass: "text-end" },
          { text: "Harga Satuan (Rp)", value: "unit_price", cellClass: "text-end" },
          { text: "Harga Total (Rp)", value: "amount", cellClass: "text-end" },
        ],
      },
      dialogAddDetail: false,
      dialogAddDetailData: {},
      dialogEditDetail: false,
      dialogEditDetailData: {},
      dialogEditSelectedIndex: null,

      tsearch2: {
        uri: "campaign/data",
        dialog: false,
        headers: [
          { text: "No.", value: "no_", class: "text-no-wrap" },
          { text: "Description", value: "description", class: "text-no-wrap" },
          { text: "Status", value: "status_code", class: "text-no-wrap" },
        ],
      },
    };
  },
  watch: {
    search_campaign(n) {
      this.getCampaign(n);
    },

    selected_campaign(n) {
      if (typeof n.no_ !== "undefined") {
        this.resume_contract_header.campaign_no_ = n.no_;
        this.resume_contract_header.campaign_description = n.description;
      }
    },
  },
  computed: {
    dtId() {
      return parseInt(this.$route.query.id);
    },
    vatValue() {
      var total = 0;
      this.resume_contract_detail.forEach((item) => {
        total += item.quantity * item.unit_price;
      });
      return !isNaN(total * (this.resume_contract_header.vat / 100)) ? total * (this.resume_contract_header.vat / 100) : 0;
    },
    totalAmount() {
      var total = 0;
      this.resume_contract_detail.forEach((item) => {
        total += item.quantity * item.unit_price;
      });
      return total;
    },
    dppAmount() {
      var total = 0;
      this.resume_contract_detail.forEach((item) => {
        total += item.quantity * item.unit_price;
      });

      if (this.resume_contract_header.vat == 0) {
        return 0;
      }

      const vat1 = 11 / 100;
      const vat2 = this.resume_contract_header.vat / 100;

      const rs = vat2 * ((vat1 / vat2) * total);
      return !isNaN(rs) ? rs : 0;
    },
    grandTotal() {
      var total = 0;
      this.resume_contract_detail.forEach((item) => {
        total += item.quantity * item.unit_price;
      });
      // tambahkan pajak
      // total += total * (this.resume_contract_header.vat / 100);

      const vat1 = 11 / 100;
      const vat2 = this.resume_contract_header.vat / 100;

      const rs = vat2 * ((vat1 / vat2) * total);
      return isNaN(total + rs) ? 0 : total + rs;
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    /**
     * getDetail
     * Method untuk mengambil data detail dokumen
     */
    async getDetail(refresh = false) {
      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      if (refresh == true) this.AxiosStorageRemove("GET", "resumecontract/detail");

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.dtId,
        },
        cacheConfig: !refresh,
      };
      await this.$axios
        .get("resumecontract/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.resume_contract_header = res.data.data.resume_contract_header;
          this.resume_contract_detail = res.data.data.resume_contract_detail;
          this.resume_contract_approval = res.data.data.resume_contract_approval;

          this.selected_campaign.no_ = this.resume_contract_header.campaign_no_;
          this.selected_campaign.description = this.resume_contract_header.campaign_description;

          this.showLoadingOverlay(false);

          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          if (typeof error.response != "undefined") {
            if (error.response.status == 404) {
              this.notFound = true;
            }
          }
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async saveData() {
      this.loading_save = true;
      this.showLoadingOverlay(true);
      var data = {
        resume_contract_header: this.resume_contract_header,
        resume_contract_detail: this.resume_contract_detail,
      };

      await this.$axios
        .post("resumecontract/save", this.objectToFormData(data))
        .then((res) => {
          this.loading_save = false;
          this.showLoadingOverlay(false);

          var resData = res.data;

          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
          }
          this.refreshDetail();
        })
        .catch((error) => {
          this.loading_save = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.refreshDetail();
        });
    },

    async getCampaign(search = "") {
      this.loading_search_campaign = true;
      await this.$axios
        .get("resumecontract/get-campaign", {
          params: {
            search: search,
          },
        })
        .then((res) => {
          this.campaign_list = res.data.data.results;
          this.loading_search_campaign = false;
        })
        .catch((error) => {
          this.loading_search_campaign = false;
          this.axiosErrorHandler(error);
        });
    },

    showDialogAddDetail() {
      this.dialogAddDetailData = {};
      this.dialogAddDetail = true;
    },

    addDetail() {
      this.resume_contract_detail.push({
        id: this.dialogAddDetailData.id,
        description: this.dialogAddDetailData.description,
        uom: this.dialogAddDetailData.uom,
        quantity: this.dialogAddDetailData.quantity,
        unit_price: this.dialogAddDetailData.unit_price,
      });

      this.dialogAddDetailData = {};
      this.dialogAddDetail = false;
    },

    deleteDetail(item) {
      var index = this.resume_contract_detail.indexOf(item);
      this.resume_contract_detail.splice(index, 1);
    },

    showDialogEditDetail(item) {
      this.dialogEditSelectedIndex = this.resume_contract_detail.indexOf(item);
      this.dialogEditDetail = true;
      const cloneItem = Object.assign({}, item);
      this.dialogEditDetailData = cloneItem;
    },

    updateDetail() {
      var index = this.dialogEditSelectedIndex;
      this.resume_contract_detail[index].id = this.dialogEditDetailData.id;
      this.resume_contract_detail[index].description = this.dialogEditDetailData.description;
      this.resume_contract_detail[index].uom = this.dialogEditDetailData.uom;
      this.resume_contract_detail[index].quantity = this.dialogEditDetailData.quantity;
      this.resume_contract_detail[index].unit_price = this.dialogEditDetailData.unit_price;
      this.dialogEditDetailData = {};
      this.dialogEditDetail = false;
    },

    handleRowSelectedCampaign(item) {
      this.tsearch2.dialog = false;
      this.resume_contract_header.campaign_no_ = item.no_;
      this.resume_contract_header.campaign_description = item.description;
    },
  },

  beforeMount() {
    if (typeof this.$route.query.id !== "undefined") {
      this.id = parseInt(this.$route.query.id);
    } else {
      this.id = parseInt(this.$route.params.id);
    }
  },

  mounted() {
    this.getDetail(true);
  },
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  vertical-align: top;
  padding-top: 5px;
}

.custom-form-label {
  font-size: 16px;
}
.custom-form-label.dense {
  font-size: 16px !important;
  line-height: 1.375rem;
  /* buat text ada di verical tengah */
  display: flex;
  align-items: center;
}
</style>
